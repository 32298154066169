import React from "react";

function CameraIcon() {
	return (
		<svg className="camera-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 56 56">
			<g fill="#fff" clipPath="url(#a)">
				<path d="M40.2 2.8H17.8v8.4h22.4V2.8zM1 14v39.2h56V14H1zm28 30.8c-6.188 0-11.2-5.012-11.2-11.2S22.812 22.4 29 22.4s11.2 5.012 11.2 11.2S35.188 44.8 29 44.8z"></path>
				<path d="M29 39.2A5.6 5.6 0 1029 28a5.6 5.6 0 000 11.2z"></path>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h56v56H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default CameraIcon;