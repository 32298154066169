import React, { memo } from 'react';
import '../css/index.scss';
import QRScan from './components/QRScan';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function App() {
	return (
		<div className={(isSafari ? ' safari' : '')}>
			<QRScan />
		</div>
	);
}

export default memo(App);
