import React from "react";

function ChevronDown({ className = '' }) {
	return (
		<svg className={`chevron-down ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" width="28" height="14" viewBox="0 0 28 14">
			<path
				stroke="var(--color, #fff)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="3"
				d="M26 2l-9.879 9.186c-1.166 1.085-3.075 1.085-4.242 0L2 2"
			></path>
		</svg>
	);
}

export default ChevronDown;