import QrScanner from 'qr-scanner';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from './ui/Button';
import { createPortal } from 'react-dom';
import Select from './ui/Select';
import CameraIcon from './ui/CameraIcon';
import { CSSTransition } from 'react-transition-group';
import LoadingSpinner from './icons/LoadingSpinner';

function Corner({ side }: { side: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' }) {
	return <svg className={`corner ${side}`} width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.04609 77.358C9.3862 77.358 12.0921 74.6398 12.0921 71.2869V12.1404H71.0143C74.3526 12.1404 77.0603 9.42221 77.0603 6.07109C77.0603 2.71996 74.3543 0 71.016 0H6.04787C2.70956 0 1.52588e-05 2.71815 1.52588e-05 6.06928V71.2869C1.52588e-05 74.6398 2.70776 77.358 6.04787 77.358H6.04609Z" fill="var(--corner-color, #FF4E02)"/>
	</svg>;
}


const url = 'https://nab24-demo-function-app.azurewebsites.net/api/leadScanner';

function scanLead(barcode) {
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			barcode,
		}),
	}).then(res => res.json());
}

export default function QRScan({ onChange, onClose, onNoCamera }: { onChange?: (result: string) => boolean, onClose?: () => void, onNoCamera?: () => void }){
	const video = useRef<HTMLVideoElement>(null);
	const qrScanner = useRef<QrScanner>();
	const lastScan = useRef<string>();

	const [scannedCode, setScannedCode] = useState<string>(null);
	const [cameraId, setCameraId] = useState<string>('');
	const [cameraList, setCameraList] = useState<QrScanner.Camera[]>([]);
	const [sendingScan, setSendingScan] = useState(false);

	const [showNoCamera, setShowNoCamera] = useState(false);
	const [showQRScanWorked, setShowQRScanWorked] = useState(false);

	const [showError, setShowError] = useState(false);

	const startScanner = useCallback(() => {
		if (!qrScanner.current) return;
		qrScanner.current.start().then(() => {
			QrScanner.listCameras(true).then((cameras) => {
				setCameraList(cameras);
			});
		}).catch((e) => {
			setShowNoCamera(true);
			onNoCamera?.();
		});
	}, [onNoCamera]);

	useEffect(() => {
		const scanner = new QrScanner(video.current, result => {
			if (lastScan.current === result.data) return;
			
			lastScan.current = result.data;
			setScannedCode(result.data);
		}, {
			highlightScanRegion: true,
			highlightCodeOutline: true,
			preferredCamera: 'environment',
		});

		qrScanner.current = scanner;
		startScanner();

		return () => {
			scanner.destroy();
		};
	}, [onChange, onNoCamera, startScanner]);

	const onClickContinue = useCallback(async () => {
		setShowError(false);
		setShowQRScanWorked(false);
		setSendingScan(true);
		
		if (scannedCode) {
			const answer = await scanLead(scannedCode);
			if (answer) {
				setScannedCode(null);
				setShowQRScanWorked(true);
			} else {
				setShowError(true);
			}
			setSendingScan(false);
		}
	}, [scannedCode]);

	useEffect(() => {
		let st;
		if (showQRScanWorked) {
			st = setTimeout(() => {
				setShowQRScanWorked(false);
			}, 5000);
		}

		return () => {
			clearTimeout(st);
		}
	}, [showQRScanWorked]);

	const onChangeCamera = useCallback((cameraId:string) => {
		qrScanner.current?.setCamera(cameraId);
		setCameraId(cameraId);
	}, []);

	return createPortal(
		<CSSTransition in={true} timeout={1500} appear mountOnEnter unmountOnExit>
			<div className="qr-scan-container">
				<div className="content">
					<div className="qr-scan-module">
						<div className="video-ctn" onClick={onClickContinue}>
							<video width="100" height="100" ref={video} />
							<Corner side='top-left' />
							<Corner side='top-right' />
							<Corner side='bottom-left' />
							<Corner side='bottom-right' />
						</div>
						{scannedCode && <div className="btn-container">
							{!sendingScan && <Button className="round continue" label="Continue" onClick={onClickContinue} icon={<CameraIcon />} />}
							{sendingScan && <Button className="round continue" label="Continue" icon={<LoadingSpinner />} />}
						</div>}
					</div>
					{showQRScanWorked && <div className="success">QR Code Scanned Successfully!</div>}
					{showError && <div className="error">Invalid QR</div>}
					{showNoCamera && <div className="error" onClick={startScanner}></div>}
					<div className="select-ctn">
						<Select placeholder="Select Camera" onChange={onChangeCamera} value={cameraId}>
							<option value="environment">Environment</option>
							<option value="user">User</option>
							<>
								{cameraList.map((camera, i) => <option key={i} value={camera.id}>{camera.label}</option>)}
							</>
						</Select>
					</div>
				</div>
			</div>
		</CSSTransition>,
		document.body
	);
}